export function fetchGameData() {
  // Endpoint URL for getting today's movie
  const url = `${process.env.REACT_APP_API_URL}/api/movie/today`;

  // Fetch the movie data from the server
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch(error => {
      console.error('Fetch error:', error);
      throw error; // Rethrow to allow the caller to handle the error
    });
}

export function fetchTitleData() {
  // Endpoint URL for getting all movie titles
  const url = `${process.env.REACT_APP_API_URL}/api/movies/titles`;

  // Fetch the movie data from the server
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch(error => {
      console.error('Fetch error:', error);
      throw error; // Rethrow to allow the caller to handle the error
    });
}

export function fetchPreviousGames() {
  // Endpoint URL for getting all movie titles
  const url = `${process.env.REACT_APP_API_URL}/api/games/previous`;

  // Fetch the movie data from the server
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch(error => {
      console.error('Fetch error:', error);
      throw error; // Rethrow to allow the caller to handle the error
    });
}

export function fetchGameByDate(date) {
  // Ensure the date is passed in the 'YYYY-MM-DD' format
  const url = `${process.env.REACT_APP_API_URL}/api/movie/date?date=${date}`;

  // Fetch the movie data from the server for the specified date
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Network response was not ok for date: ${date}`);
      }
      return response.json();
    })
    .catch(error => {
      console.error('Fetch error:', error);
      throw error; // Rethrow to allow the caller to handle the error
    });
}
