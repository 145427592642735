import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'boxicons';

const GameHeader = () => {
  const [showRules, setShowRules] = useState(false);
  const [showStats, setShowStats] = useState(false);

  const handleToggleRules = () => {
    setShowRules(!showRules);
  };

  const handleToggleStats = () => {
    setShowStats(!showStats);
  };

  // Retrieve stats from localStorage
  const gamesPlayed = localStorage.getItem('gamesPlayed') || '0';
  const gamesWon = localStorage.getItem('gamesWon') || '0';
  const currentStreak = localStorage.getItem('currentStreak') || '0';
  const winStreak = localStorage.getItem('winStreak') || '0';
  const guessDistribution = JSON.parse(localStorage.getItem('guessDistribution') || '{}');
  const maxRounds = 6;
  const allRounds = Array.from({ length: maxRounds }, (_, i) => (i + 1).toString());
  const guessDistributionWithAllRounds = allRounds.reduce((acc, round) => {
    acc[round] = guessDistribution[round] || 0;
    return acc;
  }, {});
  const maxCorrectGuesses = Math.max(...Object.values(guessDistributionWithAllRounds));
  const resetStats = () => {
    localStorage.removeItem('gamesPlayed');
    localStorage.removeItem('gamesWon');
    localStorage.removeItem('currentStreak');
    localStorage.removeItem('guessDistribution');
    localStorage.removeItem('lastPlayed');
    localStorage.removeItem('savedGuesses');
    localStorage.removeItem('currentRound');
    localStorage.removeItem('gameWon');
    localStorage.removeItem('gameOver');
    // Add any other relevant items that need to be reset
    window.location.reload(); // Optional: Refresh the page to reflect changes
  };

  return (
    <header className="text-center p-2">
      <div className="border-b border-gray-500 max-w-xl mx-auto flex items-center justify-between">
        <Link to="/"><h1 className="text-3xl font-bold mx-2 font-oswald text-white border-b-4 border-blue-500 mb-2 tracking-tighter">Acted</h1></Link>
        <nav>
          <ul className="text-2xl flex gap-3">
            <li><Link to="/"><box-icon color="white" name="home"></box-icon>
            </Link></li>
            <li><Link to="/archive"><box-icon color="white" name="calendar"></box-icon></Link></li>
            <li><span onClick={handleToggleStats} className="cursor-pointer"><box-icon color="white" name="bar-chart"></box-icon></span></li>
            <li><span onClick={handleToggleRules} className="cursor-pointer"><box-icon color="white" name="help-circle"></box-icon></span></li>
          </ul>
        </nav>
      </div>
      {showRules && (
        <div className="fixed inset-0 bg-black top-0 bottom-0 left-0 right-0 w-full h-full bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-20 p-4">
          <div className="bg-slate-800 border-2 border-white p-5 rounded-lg shadow-xl max-w-96">
            <div className="relative">
              <h2 className="text-xl font-bold mb-4">Game Rules</h2>
              <button onClick={handleToggleRules} className="top-0 right-0 absolute p-1">
                <box-icon color="white" name="x"></box-icon>
              </button>
            </div>
            <div className="text-left flex gap-5 items-center mb-5">
              <box-icon color="white" name="face" size="32px"></box-icon>
              <p>Use the actor images provided to guess the name of the movie.</p>
            </div>
            <div className="text-left flex gap-5 items-center mb-5">
              <box-icon color="white" name="x-circle" size="32px"></box-icon>
              <p>Incorrect guesses will slowly reveal more details to help you.</p>
            </div>
            <div className="text-left flex gap-5 items-center mb-5">
              <box-icon color="white" name="skip-next-circle" size="32px"></box-icon>
              <p>Leave the input blank and click 'Submit' to skip to the next round.</p>
            </div>
            <div className="text-left flex gap-5 items-center">
              <box-icon color="white" name="time-five" size="32px"></box-icon>
              <p>Games reset daily with a new movie and cast. Check out our <Link className="underline" to="/archive">archive</Link> to play previous games.</p>
            </div>
          </div>
        </div>
      )
      }
      {showStats && (
        <div className="fixed inset-0 bg-black top-0 bottom-0 left-0 right-0 w-full h-full bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-20 p-4" >
          <div className="bg-slate-800 border-2 border-white p-5 rounded-lg shadow-xl max-w-96">
            <div className="relative">
              <h2 className="text-xl font-bold mb-4">Your Stats</h2>
              <button onClick={handleToggleStats} className="top-0 right-0 absolute p-1">
                <box-icon color="white" name="x"></box-icon>
              </button>
            </div>
            <div className="text-left text-white flex gap-7 text-2xl justify-around flex-wrap">
              <div className="flex flex-col justify-center items-center"><span className="font-bold">{gamesPlayed}</span><span className="text-sm">Played</span></div>
              <div className="flex flex-col justify-center items-center"><span className="font-bold">{gamesWon}</span><span className="text-sm">Won</span></div>
              <div className="flex flex-col justify-center items-center"><span className="font-bold">{gamesPlayed === '0' ? '0.00' : ((gamesWon / gamesPlayed) * 100).toFixed(0)}%</span><span className="text-sm">Win Percentage</span></div>
              <div className="flex flex-col justify-center items-center"><span className="font-bold">{currentStreak}</span><span className="text-sm">Day Streak</span></div>
              <div className="flex flex-col justify-center items-center"><span className="font-bold">{winStreak}</span><span className="text-sm">Win Streak</span></div>
            </div>
            <div className="mt-4">
              <h3 className="text-lg font-semibold text-gray-500">Guess Distribution:</h3>
              <div className="flex items-start flex-col justify-center gap-2 mt-2 ">
                {allRounds.map(round => (
                  <div key={round} className="flex items-center gap-2 w-full">
                    <div className="text-sm font-medium">{round}</div>
                    <div
                      style={{ width: `${(guessDistributionWithAllRounds[round] / maxCorrectGuesses) * 100}%` }}
                      className={`h-5 ${guessDistributionWithAllRounds[round] > 0 ? 'bg-blue-500' : 'bg-gray-500'} flex items-center justify-center rounded p-3`}
                    >
                      <span className="text-white text-xs">{guessDistributionWithAllRounds[round]}</span>
                    </div>
                  </div>
                ))}

              </div>
            </div>
            {/* <button onClick={resetStats} className="mt-4 px-4  text-white underline">
              Reset Stats
            </button> */}
          </div>
        </div>
      )}
    </header >
  );
};

export default GameHeader;
