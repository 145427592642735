import React, { useState } from 'react';
import 'boxicons';

const GuessForm = ({ onGuessSubmit, onReset, onSkip, titleData, movieTitle }) => {
  const [guess, setGuess] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleChange = (e) => {
    const value = e.target.value.toLowerCase();
    setGuess(value);

    if (value.length > 0) {
      const filteredTitles = titleData
        .map(title => title.titleText.text)
        .filter(title => title.toLowerCase().includes(value));
      setSuggestions(filteredTitles);
    } else {
      setSuggestions([]);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (titleData.some(title => title.titleText.text.toLowerCase() === guess.toLowerCase()) || guess === '') {
      onGuessSubmit(guess);
      setGuess('');
      setSuggestions([]);
    } else {
      alert('Please select a valid movie title.');
    }
  };

  return (
    <div className="text-center p-2 pb-2 max-w-xl m-auto relative">
      {suggestions.length > 0 && (
        <ul className="absolute bg-white text-black border border-gray-700 w-8/12 max-w-xl rounded-md max-h-36 overflow-auto z-10 text-left p-1 bottom-12">
          {suggestions.map((suggestion, index) => (
            <li key={index}
              className="p-1 hover:bg-slate-300 cursor-pointer rounded-md"
              onClick={() => {
                setGuess(suggestion);
                setSuggestions([]);
              }}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
      <form className="flex items-center justify-center gap-2" onSubmit={handleSubmit}>
        <div className="relative flex-grow">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <box-icon color="white" name="search"></box-icon>
          </span>
          <input
            type="text"
            className="text-sm form-input text-white bg-gray-700 pl-9 pr-2 py-2 border border-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-full"
            placeholder="Search for a movie or submit to skip"
            value={guess}
            onChange={handleChange}

          />
        </div>
        <button
          type="submit"
          className="px-2 py-2 bg-blue-500 text-sm text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:bg-blue-300"
          disabled={!movieTitle || movieTitle.length === 0}
        >
          Submit
        </button>
      </form>
    </div>

  );
};

export default GuessForm;
