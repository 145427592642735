import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

const GameResultDisplay = ({ isCorrect, movieTitle, totalGuesses, maxGuesses, guesses, isArchive }) => {
  const [showGuesses, setShowGuesses] = useState(false);
  const navigate = useNavigate();
  const startDay = moment('2024-01-21');
  const currentDay = moment().tz("Australia/Brisbane");
  const daysSinceStart = currentDay.diff(startDay, 'days');

  const calculateTimeTillReset = () => {
    // Get the current time in AEST
    const nowAEST = moment.tz("Australia/Brisbane");

    // Get the next midnight in AEST
    const midnightAEST = nowAEST.clone().add(1, 'days').startOf('day');

    // Calculate the difference in milliseconds
    const diff = midnightAEST.diff(nowAEST);

    // Convert the difference to hours, minutes, and seconds
    const duration = moment.duration(diff);
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const [timeTillReset, setTimeTillReset] = useState(calculateTimeTillReset());

  useEffect(() => {
    // Update timer every second
    const interval = setInterval(() => {
      setTimeTillReset(calculateTimeTillReset());
    }, 1000);

    // Clear interval on unmount
    return () => clearInterval(interval);
  }, []);

  const guessEmojis = Array(maxGuesses).fill('⬜').map((emoji, index) => {
    if (index < totalGuesses - 1) return '🟥';
    if (index === totalGuesses - 1) return isCorrect ? '🟩' : '🟥';
    return emoji;
  });

  const toggleGuesses = () => {
    setShowGuesses(!showGuesses);
  };

  const handleShare = () => {
    const emojiString = guessEmojis.join('');
    const shareText = `Acted #${daysSinceStart + 1} \n${emojiString}\n\nhttps://acted.wtf`;
    navigator.clipboard.writeText(shareText)
      .then(() => alert('Result copied to clipboard!'))
      .catch(err => console.error('Error in copying text: ', err));
  };

  const handleBackToArchive = () => {
    navigate('/archive');
  };

  return (
    <div className="text-center mt-10 max-w-xs m-auto">
      <h2 className="text-xl font-semibold text-gray-500">
        {isCorrect ? "You got it! The answer was:" : "The answer was:"}
      </h2>
      <h2 className="text-2xl font-bold">{movieTitle}</h2>
      <p className="text-xl mt-4">{guessEmojis.join('')}</p>
      <div className="flex flex-col mt-4">
        <button
          onClick={handleShare}
          className="mt-4 bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600"
        >
          Share
        </button>
        {isArchive && (
          <button onClick={handleBackToArchive} className="bg-gray-500 text-white rounded px-4 py-2 mt-4">
            Back to Archive
          </button>
        )}
        {!isArchive && (
          <div className="mt-2">
            <p className="text-gray-500">Next Movie:</p>
            <p className="font-bold text-md">{timeTillReset}</p>
          </div>
        )}
        <button
          onClick={toggleGuesses}
          className="mt-2 text-white py-2 px-4 underline"
        >
          {showGuesses ? 'Hide Guesses' : 'Show Guesses'}
        </button>
        {showGuesses && (
          <div className="mt-2">
            <h3 className="font-semibold"></h3>
            <ul>
              {guesses.map((guess, index) => (
                <li key={index} className="text-white">{guess || 'Skipped'}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameResultDisplay;
