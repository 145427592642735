import React from 'react';

const GuessesDisplay = ({ guesses, correctAnswer, remainingGuesses }) => {
  // Create a reversed copy of the guesses array
  const reversedGuesses = [...guesses].reverse();

  return (
    <div className="px-2 max-w-xl m-auto max-h-32 sm:max-h-full overflow-y-auto">
      {reversedGuesses.map((guess, index) => (
        <div
          key={index}
          className={`p-1 my-1 mt-2 rounded border ${guess.toLowerCase() === correctAnswer ? 'border-gray-600' : 'border-gray-600'} flex items-center`}
          style={{ minWidth: '100px' }}
        >
          <span>{guess.toLowerCase() === correctAnswer ? '🟩' : '🟥'}</span>
          <span className="text-white px-2">{guess || 'Skipped'}</span>

        </div>
      ))}
      {remainingGuesses > 0 && (
        <div className="p-1 mt-2 h-8 my-1 rounded border bg-slate-800 opacity-25 flex items-center justify-between" style={{ minWidth: '100px' }}>
          <span className="text-white"></span>
        </div>
      )}
    </div>
  );
};

export default GuessesDisplay;
