import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { fetchGameByDate, fetchTitleData } from '../api';
import Game from './Game';
import moment from 'moment-timezone';
import 'boxicons';

const Archive = () => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loadingGame, setLoadingGame] = useState(false);
  const [titleData, setTitleData] = useState(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const startDay = moment('2024-01-21');
  const currentDay = moment().tz("Australia/Brisbane");
  const daysSinceStart = currentDay.diff(startDay, 'days');

  useEffect(() => {
    const dayParam = searchParams.get('day');
    if (dayParam) {
      const day = parseInt(dayParam, 10); // Convert to number
      const gameDate = startDay.clone().add(day - 1, 'days');

      if (gameDate.isBefore(currentDay, 'day')) {
        setLoadingGame(true);
        setSelectedDay(day);
        setSelectedDate(gameDate.format('MMM DD, YYYY'));
        fetchGameByDate(gameDate.format('YYYY-MM-DD')).then(data => {
          setSelectedGame(data);
          setLoadingGame(false);
        }).catch(error => {
          console.error('Error fetching game:', error);
        });
        fetchTitleData().then(data => {
          setTitleData(data);
        });
      }
    } else {
      setSelectedGame(null);
    }
  }, [searchParams, location.pathname]);


  const handleGameSelect = (day) => {
    setLoadingGame(true);
    setSelectedGame(null);
    navigate(`/archive?day=${day}`);
  };

  const navigateToDay = (day) => {
    setLoadingGame(true);
    setSelectedGame(null);
    navigate(`/archive?day=${day}`);
  };

  return (
    <div className="archive-container">
      {(!selectedGame && !loadingGame) && (
        <>
          <h2 className="text-2xl font-bold mb-4 text-center text-white">Archive</h2>
          <div className="game-selection justify-center px-2 max-w-xl m-auto flex flex-wrap gap-5">
            {[...Array(daysSinceStart)].map((_, index, arr) => (
              <button className="bg-slate-600 text-white rounded-md w-16 h-16 flex justify-center items-center text-2xl font-bold" key={index} onClick={() => handleGameSelect(arr.length - index)}>
                {arr.length - index}
              </button>
            ))}
          </div>

        </>
      )}

      {(loadingGame || selectedGame) && (
        <div className="game-navigation px-2 max-w-xl m-auto items-center relative">
          {selectedDay > 1 && (

            <button className="text-2xl absolute top-0 left-0 px-2" onClick={() => navigateToDay(selectedDay - 1)} disabled={selectedDay <= 1}>
              <box-icon color="white" name="left-arrow-alt"></box-icon>
            </button>
          )}
          <h2 className="text-xl font-semibold italic text-center text-white">#{selectedDay} - {selectedDate}</h2>
          {selectedDay < daysSinceStart && (

            <button className="text-2xl absolute top-0 right-0 px-2" onClick={() => navigateToDay(selectedDay + 1)} disabled={selectedDay >= daysSinceStart}>
              <box-icon color="white" name="right-arrow-alt"></box-icon>
            </button>
          )}
        </div>
      )}

      {loadingGame && (
        <>
          <Game gameData={selectedGame} isArchive={true} titleData={titleData} />
        </>
      )}

      {(selectedGame && !loadingGame) && (
        <>
          <Game gameData={selectedGame} isArchive={true} titleData={titleData} />
        </>
      )}
    </div>
  );
};

export default Archive;