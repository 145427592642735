import React from 'react';

const RemainingGuessesDisplay = ({ remainingGuesses }) => {
  const guessText = remainingGuesses === 1 ? 'GUESS' : 'GUESSES';

  return (
    <div className="mt-4 px-2 ">
      <div className=" border-t pt-2 border-gray-500 max-w-xl m-auto">
        <p className="text-gray-500 text-center">
          {remainingGuesses} {guessText} REMAINING
        </p>
      </div>
    </div>

  );
};

export default RemainingGuessesDisplay;
