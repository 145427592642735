import React, { useState, useEffect } from 'react';
import GameHeader from './GameHeader';
import CastDisplay from './CastDisplay';
import GuessForm from './GuessForm';
import GuessesDisplay from './GuessesDisplay';
import GameResultDisplay from './GameResultDisplay';
import RemainingGuessesDisplay from './RemainingGuessesDisplay';
import { fetchTitleData } from '../api';
import CastDisplayLoading from './CastDisplayLoading';
import moment from 'moment-timezone';

const Game = ({ gameData, isArchive, titleData }) => {
  const [title, setTitle] = useState('');
  const [titles, setTitles] = useState([]);
  const [cast, setCast] = useState([]);
  const [currentRound, setCurrentRound] = useState(1);
  const [guesses, setGuesses] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const maxRounds = 6;
  const remainingGuesses = maxRounds - currentRound + 1;

  const getStorageKey = (key) => {
    return isArchive ? `archive_${key}` : key;
  };

  useEffect(() => {
    if (!isArchive) { // Check if it's not an archive game
      const today = moment().tz("Australia/Brisbane").format('YYYY-MM-DD');
      const lastPlayed = localStorage.getItem(getStorageKey('lastPlayed'));
      const savedGameOver = localStorage.getItem(getStorageKey('gameOver'));
      const savedGameWon = localStorage.getItem(getStorageKey('gameWon'));
      const savedGuesses = localStorage.getItem(getStorageKey('guesses'));
      const savedCurrentRound = localStorage.getItem(getStorageKey('currentRound'));

      if (today === lastPlayed) {
        setGuesses(savedGuesses ? JSON.parse(savedGuesses) : []);
        if (savedGameOver === 'false') {
          if (savedCurrentRound && (Number(savedCurrentRound) < maxRounds)) {
            setCurrentRound(Number(savedCurrentRound));
          }
        } else {
          setGameOver(savedGameOver === 'true');
          setGameWon(savedGameWon === 'true');
        }
      } else {
        // Initialize the game with fresh data for a new day
        localStorage.setItem(getStorageKey('gameOver'), 'false');
        localStorage.setItem(getStorageKey('gameWon'), 'false');
        localStorage.setItem(getStorageKey('guesses'), JSON.stringify([]));
        localStorage.setItem(getStorageKey('currentRound'), '1');
      }
    }

    if (gameData) {
      setTitle(gameData.titleText.text);
      setCast(gameData.cast.edges.map(edge => edge.node));
    }

    if (titleData) {
      setTitles(titleData);
    }

  }, [gameData, titleData, isArchive]);

  const displayRound = gameOver ? maxRounds : currentRound;

  const updateGameStats = (isWin) => {
    if (isArchive) return; // Skip updating stats for archive games

    const today = moment().tz("Australia/Brisbane").format('YYYY-MM-DD');
    const yesterday = moment().tz("Australia/Brisbane").subtract(1, 'days').format('YYYY-MM-DD');
    const lastCompleted = localStorage.getItem(getStorageKey('lastCompleted'));
    const lastWon = localStorage.getItem(getStorageKey('lastWon'));
    const gamesPlayed = parseInt(localStorage.getItem('gamesPlayed') || '0');
    const gamesWon = parseInt(localStorage.getItem('gamesWon') || '0');
    const currentStreak = parseInt(localStorage.getItem('currentStreak') || '0');
    const winStreak = parseInt(localStorage.getItem('winStreak') || '0');

    localStorage.setItem('gamesPlayed', gamesPlayed + 1);

    if (isWin) {
      localStorage.setItem('gamesWon', gamesWon + 1);

      if (lastWon === yesterday) {
        localStorage.setItem('winStreak', winStreak + 1);
      } else {
        localStorage.setItem('winStreak', 1);
      }

      localStorage.setItem('lastWon', today);
    }

    if (lastCompleted === yesterday) {
      // User played yesterday (according to AEST), increment streak
      localStorage.setItem('currentStreak', currentStreak + 1);
      // Update last completed date to today
      localStorage.setItem('lastCompleted', moment().tz("Australia/Brisbane").format('YYYY-MM-DD'));
    } else {
      // User didn't play yesterday (according to AEST), reset streak
      localStorage.setItem('currentStreak', 1);
      // Update last completed date to today
      localStorage.setItem('lastCompleted', moment().tz("Australia/Brisbane").format('YYYY-MM-DD'));
    }

    localStorage.setItem('lastPlayed', today);
  };

  const handleGuessSubmit = (guess) => {
    const today = moment().tz("Australia/Brisbane").format('YYYY-MM-DD');
    localStorage.setItem('lastPlayed', today);

    const trimmedGuess = guess.trim().toLowerCase();
    const lowerCaseTitle = title.trim().toLowerCase();

    setGuesses(prevGuesses => {
      const newGuesses = [...prevGuesses, guess || 'Skipped'];
      localStorage.setItem(getStorageKey('guesses'), JSON.stringify(newGuesses));
      return newGuesses;
    });

    if (trimmedGuess === lowerCaseTitle) {
      // User guessed correctly
      const guessDistribution = JSON.parse(localStorage.getItem('guessDistribution') || '{}');
      guessDistribution[currentRound] = (guessDistribution[currentRound] || 0) + 1;
      localStorage.setItem('guessDistribution', JSON.stringify(guessDistribution));

      handleGameOver(true); // The user won
    } else {
      if (currentRound < maxRounds) {
        setCurrentRound(prevRound => {
          const newRound = prevRound + 1;
          localStorage.setItem(getStorageKey('currentRound'), JSON.stringify(newRound));
          return newRound;
        });
      } else {
        handleGameOver(false); // The user lost
      }
    }
  };

  const handleGameOver = (isWin) => {
    setGameOver(true);
    setGameWon(isWin);

    if (!isArchive) {
      localStorage.setItem(getStorageKey('gameOver'), 'true');
      localStorage.setItem(getStorageKey('gameWon'), isWin.toString());
      localStorage.setItem('lastPlayed', moment().tz("Australia/Brisbane").format('YYYY-MM-DD'));
      updateGameStats(isWin);
    }
  };

  const handleReset = () => {
    resetGame();
  };

  const handleSkip = () => {
    handleGuessSubmit('');
  };

  const resetGame = () => {
    setCurrentRound(1);
    setGuesses([]);
    localStorage.setItem(getStorageKey('currentRound'), '1');
    localStorage.setItem(getStorageKey('guesses'), JSON.stringify([]));
  };

  return (
    <>

      {(!gameData || (titles.length === 0)) && (
        <CastDisplayLoading />
      )}


      {(gameData && (titles.length > 0)) && (
        <CastDisplay cast={cast} round={displayRound} />
      )}

      {!gameOver && (
        <>
          <GuessForm titleData={titles} onGuessSubmit={handleGuessSubmit} onReset={handleReset} onSkip={handleSkip} movieTitle={title} />
          <GuessesDisplay
            guesses={guesses}
            correctAnswer={title}
            remainingGuesses={remainingGuesses}
          />
          <RemainingGuessesDisplay remainingGuesses={remainingGuesses} />
        </>
      )}
      {gameOver && (
        <GameResultDisplay
          isCorrect={gameWon}
          movieTitle={title}
          totalGuesses={guesses.length}
          maxGuesses={maxRounds}
          guesses={guesses}
          isArchive={isArchive}
        />
      )}
    </>
  );
};

export default Game;
