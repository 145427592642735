import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'boxicons';

const GameFooter = () => {

  return (
    <>
      <footer className="text-center mt-12 px-4">
        <div className="max-w-xl mx-auto">
          <Link to="https://twitter.com/actedwtf" target="_blank"><span className="text-md text-gray-500 underline mx-2text-white mb-2 ">Give us feedback!</span></Link>
        </div>
      </footer >
    </>
  );
};

export default GameFooter;
