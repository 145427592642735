import React, { useState } from 'react';
import { Lightbox } from "react-modal-image";

const getImageSrcSet = (fullUrl) => {
  const sizes = [140, 210, 280];
  return sizes.map((size) => `${fullUrl.replace('._V1_.jpg', `._V1_QL75_UX${size}_CR0,0,${size},${size}_.jpg`)} ${size}w`).join(', ');
};

const CastMember = ({ member, showNameDetails, showCharDetails, isVisible }) => {
  const fullImageUrl = member?.name?.primaryImage?.url;
  const srcSet = getImageSrcSet(fullImageUrl);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const openLightbox = () => {
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
      <div
        className={`cast_member flex flex-col items-center w-28 sm:w-36 mx-auto ${isVisible ? 'visible' : 'hidden'}`}
        onClick={openLightbox}
        style={{ cursor: 'pointer' }}
      >
        <img
          className="w-28 sm:w-36 h-28 sm:h-36 object-cover border-2 border-gray-200"
          src={fullImageUrl}
          srcSet={srcSet}
          sizes="(max-width: 600px) 140px, (max-width: 900px) 210px, 280px"
          alt={member?.name?.nameText?.text}
        />
        <div className="mt-2 flex flex-col items-center w-full">
          {showNameDetails ? (
            <p className="w-28 sm:w-36 mb-1 text-sm font-semibold leading-4 text-white">
              {member.name.nameText.text}
            </p>
          ) : (
            <p className="w-28 sm:w-36 mb-1 text-sm font-semibold leading-4 text-white h-4 bg-slate-800"></p>
          )}
          {showCharDetails && member.characters.length > 0 ? (
            <p className="w-28 sm:w-36 text-gray-400 text-sm leading-4">
              {`as ${member.characters[0].name}`}
            </p>
          ) : (
            <p className="w-28 sm:w-36 text-gray-400 text-sm leading-4 h-4 bg-slate-700"></p>
          )}
        </div>
      </div>
      {lightboxOpen && (
        <Lightbox
          medium={fullImageUrl}
          large={fullImageUrl}
          onClose={closeLightbox}
          hideDownload
        />
      )}
    </>
  );
};


const CastMemberMemo = React.memo(CastMember);

const CastDisplay = ({ cast, round }) => {
  let displayCount = round === 1 ? 2 : round === 2 ? 3 : round === 3 ? 4 : round === 4 ? 5 : 6;
  let skipCount = round === 1 ? 4 : round === 2 ? 3 : round === 3 ? 2 : round === 4 ? 1 : 0;

  // Filter out members without a valid picture URL
  let filteredCast = cast.filter(member => member?.name?.primaryImage?.url);

  let displayCast = filteredCast.slice(skipCount, skipCount + displayCount);

  return (
    <div className="w-64 sm:w-80 grid grid-cols-2 gap-5 justify-center mx-auto mt-4 mb-6">
      {filteredCast.map((member) => (
        <CastMemberMemo
          key={member.id}
          member={member}
          showNameDetails={round >= 5}
          showCharDetails={round >= 6}
          isVisible={displayCast.includes(member)}
        />
      ))}
    </div>
  );
};

export default CastDisplay;
