import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Game from './components/Game';
import Archive from './components/Archive';
import GameHeader from './components/GameHeader';
import { fetchGameData, fetchTitleData } from './api';
import GameFooter from './components/GameFooter';

function App() {
  const [initialGame, setInitialGame] = useState(null);
  const [titleData, setTitleData] = useState(null);

  useEffect(() => {
    fetchGameData().then(data => {
      setInitialGame(data);
    });
    fetchTitleData().then(data => {
      setTitleData(data);
    });

  }, []);

  return (
    <Router>
      <div className="bg-slate-900 text-white min-h-screen pb-10">
        <GameHeader />
        <Routes>
          <Route path="/archive" element={<Archive />} />
          <Route path="/" element={<Game gameData={initialGame} titleData={titleData} />} />
        </Routes>
        <GameFooter />
      </div>
    </Router>
  );
}

export default App;
