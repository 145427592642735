import React from 'react';

const CastDisplayLoading = () => {

  return (
    <div className="grid grid-cols-2 gap-5 justify-center  mx-auto mt-4 mb-6 w-64 sm:w-80">
      {
        Array.from({ length: 2 }).map((_, index) => (
          <div className="cast_member flex flex-col items-center w-28 sm:w-36 mx-auto" key={index}>
            <div className="w-28 sm:w-36 h-28 sm:h-36 border-2 border-gray-700 animate-pulse"></div>
            <div className="mt-2 flex flex-col items-center w-full">
              <p className={`w-28 sm:w-36 mb-1 text-sm font-semibold leading-4 text-white h-4 bg-slate-800 animate-pulse`}>
              </p>
              <p className={`w-28 sm:w-36 text-gray-400 text-sm leading-4 h-4 bg-slate-700 animate-pulse`}>
              </p>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default CastDisplayLoading;
